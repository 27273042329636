import React from "react"
import NewsSlider from "../../sliders/NewsSlider"
import { graphql, useStaticQuery } from "gatsby"

const MoreNews = () => {
  const moreNews = useStaticQuery(graphql`
    {
      allContentfulNewsEvents {
        nodes {
          id
          title
          slug
          date(formatString: "DD MMMM YYYY")
          shortDesc
          body {
            raw
            references {
              gatsbyImageData
            }
          }
          thumbnail {
            gatsbyImageData(placeholder: TRACED_SVG, layout: CONSTRAINED)
          }
        }
      }
    }
  `)

  return (
    <section className="slider">
      <div className="container">
        <h2 className="latest-news-title">Our Latest News & Events</h2>
        <NewsSlider contents={moreNews.allContentfulNewsEvents.nodes} />
      </div>
    </section>
  )
}

export default MoreNews
