import React from "react"
import "../../components/layout/cssberhasil"
import Navbar from "../../components/layout/NavbarSub"
import Header from "../../components/layout/HeaderSub"
import Footer from "../../components/layout/Footer"
import Mobile from "../../components/content/Mobile"
import SEO from "../../components/SEO"
import NewsDetailBanner from "../../components/content/News/NewsDetailBanner"
import MoreNews from "../../components/content/News/MoreNews"

import Helmet from "react-helmet"
import JSberhasil from "../../components/layout/jsberhasil"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"

const NewsDetail = (data) => {
  const news = data.data.news
  const richContent = news.body
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        return (
          <>
            {" "}
            <GatsbyImage
              style={{
                marginBottom: "20px",
                marginTop: "20px",
                display: "block",
              }}
              image={getImage(node.data.target.gatsbyImageData)}
            />
          </>
        )
      },
    },

    renderMark: {},
  }

  return (
    <React.Fragment>
      <Helmet
        bodyAttributes={{
          class:
            "home page-template-default page page-id-4074 theme-avante woocommerce-no-js menu-transparent lightbox-black leftalign footer-reveal elementor-default elementor-page elementor-page-4074",
        }}
      />
      <SEO
        // title="News Detail | "
        title={`Pharmametrics Lab | ${news.title}`}
        description="A Contract Research Organization (CRO) based in Indonesia providing full range of clinical trial and contract services to major companies in ASEAN"
      />
      <div id="perspective">
        <Mobile />
        <div id="wrapper" class="hasbg transparent">
          <div id="elementor-header" class="main-menu-wrapper">
            <div
              data-elementor-type="wp-post"
              data-elementor-id="4287"
              class="elementor elementor-4287"
              data-elementor-settings="[]"
            >
              <div class="elementor-inner">
                <div class="elementor-section-wrap">
                  <Header />

                  <Navbar />
                </div>
              </div>
            </div>
          </div>
          <div id="elementor-sticky-header" className="main-menu-wrapper">
            <div
              data-elementor-type="wp-post"
              data-elementor-id="4287"
              className="elementor elementor-4287"
              data-elementor-settings="[]"
            >
              <div className="elementor-inner">
                <div className="elementor-section-wrap">
                  <Navbar />
                </div>
              </div>
            </div>
          </div>

          <div id="page-content-wrapper" class="wide">
            <div class="inner">
              <div class="inner-wrapper">
                <div class="sidebar-content fullwidth">
                  <div
                    data-elementor-type="wp-page"
                    data-elementor-id="5459"
                    class="elementor elementor-5459"
                    data-elementor-settings="[]"
                  >
                    <div class="elementor-inner">
                      <div class="elementor-section-wrap">
                        <NewsDetailBanner />
                        <section className="news-container">
                          <div className="content-detail">
                            <h2 className="news-detail-title pt-5">
                              {news.title}
                            </h2>
                            <h2 className="news-detail-date">{news.date}</h2>
                            {/* <StaticImage
                              src="../../../../images/clinical5.jpg"
                              layout="constrained"
                              height={500}
                              width={800}
                            /> */}
                            <GatsbyImage
                              image={getImage(news.thumbnail)}
                              layout="constrained"
                            />
                            <div className="news-detail-content">
                              {richContent &&
                                renderRichText(richContent, options)}
                            </div>
                          </div>
                        </section>
                        <MoreNews />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <JSberhasil />
    </React.Fragment>
  )
}

export default NewsDetail

export const query = graphql`
  query getNewsDetail($slug: String) {
    news: contentfulNewsEvents(slug: { eq: $slug }) {
      title
      slug
      date(formatString: "DD MMMM YYYY")
      thumbnail {
        gatsbyImageData(quality: 100)
      }
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            gatsbyImageData(quality: 100, layout: CONSTRAINED)
            __typename
          }
        }
      }
    }
  }
`
